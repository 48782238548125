<template>
  <div
    v-if="dialog?.isOpen"
    class="dialog-view"
    @click.self.prevent="close"
  >
    <component
      :id="dialog?.id"
      :is="dialog?.componentName"
      @close="close"
    />
    <!-- <button @click="close">Close</button> --> <!-- propsによって表示/非表示の切り替え？ -->
  </div>
</template>

<script setup lang="ts">
const { id } = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const dialogStore = useDialogStore()
const dialog = computed(() => dialogStore.dialogs.find(dialog => dialog.id === id))

const close = () => {
  dialogStore.closeDialog(id)
}

watchEffect(() => {
  if (dialog?.value && !dialog?.value.isOpen) {
    dialogStore.deleteDialog(id)
  }
})
</script>

<style lang="scss" scoped>
.dialog-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: v-bind('`${dialog?.zIndex}`');
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
